<template>
  <div>
    <div class="container">
      <div class="form-box">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
        <el-form-item label="设置新密码" prop="choose">
          <el-input v-model="form.choose" show-password  placeholder="请设置新密码" @click="visible = true"></el-input>
        </el-form-item>
       <!-- 填写组织名称 -->
        <el-form-item label="确认新密码" prop="name">
           <el-input v-model="form.name" show-password  placeholder="请再次输入新密码"></el-input>
        </el-form-item>
        <!-- 提交按钮 -->
        <div class="submit">
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </div>
      </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,reactive } from "vue";
import { ElMessage } from "element-plus";
import { Alter } from "../utils/api"
import { useRouter } from "vue-router";
import qs from 'qs'
export default {
  name:"personage",
  setup() {
    // 获取新密码
    // let newpass=ref('')
    // 表单内容
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      choose:""
    })
    const router = useRouter();
    // 表单提交
    const onSubmit = ()=>{
      // 表单校验
      ruleFormRef.value.validate((valid)=>{
        if(valid==true){
          if(Object.values(form)[0]==Object.values(form)[1]){
            let data={
              password:form.name
            }
            Alter(qs.stringify(data)).then((res)=>{
             console.log(res)
             if(res.data.code==0){
              ElMessage({
                message:'修改成功,请重新登录',
                type: "success",
                center: true,
              });
              setTimeout(()=>{
                location. reload() 
                sessionStorage.setItem('token', '')
                router.push("/Login")
              },1000)
             }
            })
            console.log(qs.stringify(data))
          }else{
            ElMessage({
              message:'两次输入的密码不一致',
              type: "error",
              center: true,
            });
          }
        }
      })
      console.log(form)
    }
    // 验证表单提示
    const rules ={
      name:[
        { required: true, message: "请填写确认密码", trigger: "blur" },
      ],
      choose:[
        { required: true, message: "请填写修改密码", trigger: "blur" },
      ]
    }
    return {
      // 表单内容
      ruleFormRef,
      form,
      // 表单提交按钮
      onSubmit,
      rules
    }
  },
}
</script>>

<style lang='scss'>
.el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
.submit{
  margin-left: 80px;
}
.el-input{
  height: 44px;
}
.el-form-item{
  margin-bottom: 30px;
  align-items: center;
}
.el-tree-node__content{
  font-weight: 500;
}
.el-select-dropdown__item.hover{
  background-color: #ffffff;
}
.el-select-dropdown__wrap{
  background-color: #ffffff;
}
.form-box{
  width: 500px;
}
.el-form-item__content{
  margin-left: 0 !important;
}
.container{
  max-width: 91%;
  margin: auto;
  margin-top: 10px;
  padding: 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>